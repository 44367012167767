import React, { useState, useEffect } from 'react';
import Marquee from "react-fast-marquee";
import image1 from "../imgs/disney+_logo.webp";
import image2 from "../imgs/nanoleaf_logo.webp";
import image3 from "../imgs/spotify_logo.webp";
import image4 from "../imgs/ubereats_logo.webp";
import image5 from "../imgs/allybank_resize.png";

export const brands = [
  {
    img: image1,
  },

  {
    img: image2,
  },
  {
    img: image3,
  },
  {
    img: image4,
  },
  {
    img: image5,
  },

];

export const Marque = () => {
  const [shouldScroll, setShouldScroll] = useState(brands.length > 7)

  useEffect(() => {
    const handleResize = () => {
      setShouldScroll(brands.length > 7 || window.innerWidth < 1200);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const brandImages = brands.map((brand, index) => (
    <img src={brand.img} alt={`brand-logo-${index}`} className="brand_logo" key={index} />
  ))

  return (
    <div className="marquee-container">
      {shouldScroll ?
        (<Marquee autoFill={true}>
          {brandImages}
        </Marquee>) : (
          <div className='brands'>{brandImages}</div>
        )}
    </div>
  );
};
